<template>
  <div>
    <a-card title="基础信息" class="iss-card" style="margin-bottom: 10px">
      <a-form ref="form" :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-item name="name" label="名称">
              <a-input v-model:value="form.name" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="remark" label="备注">
              <a-input v-model:value="form.remark" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="群组标签">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-tree
                style="max-height: 267px; overflow-y: auto"
                checkable
                v-model:checked-keys="treeDefaultChecked"
                :tree-data="tagTree"
                :replace-fields="{ key: 'tag_code', title: 'tag_name' }"
                @check="tagTreeCheckChange"
              />
            </a-col>
            <a-col :span="12">
              <a-space class="iss-space">
                <a-tag
                  :key="tag.tagCode"
                  v-for="tag in dynamicTags"
                  color="purple"
                  closable
                  @close="handleClose(tag)"
                >
                  {{ tag.tagName }}
                </a-tag>
              </a-space>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-card>
    <a-card title="群组规则" class="iss-card" style="margin-bottom: 10px">
      <div class="iss-regulations">
        <!--分群规则 start-->
        <ul class="condition-group-list">
          <!--条件组列表 -->
          <li
            class="condition-group-item"
            :key="index"
            v-for="(item, index) in groupRuleList"
          >
            <div class="condition-group-type-wrapper">
              <a class="and-btn"></a>
            </div>
            <ul class="condition-list">
              <!--条件列表 -->
              <li
                class="condition-item"
                :key="conditionIndex"
                v-for="(condition, conditionIndex) in item"
              >
                <div class="condition-type-wrapper">
                  <span class="or-btn"></span>
                </div>
                <div class="condition-wrapper">
                  <table class="condition-table">
                    <!--属性满足-->
                    <tr v-if="condition.type === 'property'">
                      <td style="width: 14%">
                        <span class="condition-type"> 属性满足 </span>
                        <a
                          class="remove-btn"
                          @click="removeRule(index, conditionIndex)"
                        />
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="condition.col"
                          placeholder="请选择属性"
                          @change="handleChangeProperty($event, condition)"
                        >
                          <a-select-option
                            v-for="p in propertyList"
                            :key="p.colCode"
                            :value="p.colCode"
                          >
                            {{ p.colName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 15%">
                        <a-select
                          v-model:value="condition.operator"
                          placeholder="请选择操作符"
                        >
                          <a-select-option
                            v-for="o in operatorListMap[condition.dataType]"
                            :key="o.symbol"
                            :value="o.symbol"
                          >
                            {{ o.symbolName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td v-if="condition.col === 'country'" style="width: 53%">
                        <el-autocomplete
                          style="width: 50%"
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          aria-required="true"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                areaOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入国家…"
                        />
                      </td>
                      <td
                        v-else-if="condition.col === 'region'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          aria-required="true"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                areaDeptOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入地区..."
                        />
                      </td>
                      <td
                        v-else-if="condition.col === 'industry_lv1'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          aria-required="true"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                industryLv1Options.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入行业..."
                        />
                      </td>
                      <td
                        v-else-if="condition.col === 'customer_type'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          aria-required="true"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                CUSTOMETYPEOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入客户类型..."
                        />
                      </td>
                      <td
                        v-else-if="condition.col === 'job_title'"
                        style="width: 53%"
                      >
                        <el-autocomplete
                          style="width: 50%"
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          aria-required="true"
                          :fetch-suggestions="
                            (queryString, cb) =>
                              cb(
                                JobTitleOptions.filter(item =>
                                  item.value.includes(queryString)
                                )
                              )
                          "
                          placeholder="请输入客户类型..."
                        />
                      </td>
                      <td
                        v-else-if="condition.col === 'is_activate'"
                        style="width: 53%"
                      >
                        <el-select
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          style="width: 50%"
                          aria-required="true"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in is_activateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </td>
                      <td
                        v-else-if="condition.dataType === 'time'"
                        style="width: 53%"
                      >
                        <el-date-picker
                          v-if="condition.operator !== '('"
                          v-model="condition.value"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        />
                        <el-date-picker
                          v-if="
                            ['()', '[)', '(]', '[]'].includes(
                              condition.operator
                            )
                          "
                          v-model="condition.secondValue"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        />
                      </td>
                      <td v-else style="width: 53%">
                        <a-textarea
                          v-if="condition.operator !== '('"
                          v-model:value="condition.value"
                          auto-size
                          placeholder="请输入..."
                        />
                        <a-input
                          v-if="
                            ['()', '[)', '(]', '[]'].includes(
                              condition.operator
                            )
                          "
                          v-model:value="condition.secondValue"
                          placeholder="请输入..."
                        />
                      </td>
                    </tr>
                    <!--行为满足-->
                    <tr v-if="condition.type === 'behavior'">
                      <td style="width: 14%">
                        <span class="condition-type"> 行为满足 </span>
                        <a
                          class="remove-btn"
                          @click="removeRule(index, conditionIndex)"
                        />
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="condition.col"
                          placeholder="请选择行为"
                        >
                          <a-select-option
                            v-for="ap in actionPropertyList"
                            :key="ap.colCode"
                            :value="ap.colCode"
                          >
                            {{ ap.colName }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 15%">
                        <a-select
                          v-model:value="condition.action"
                          placeholder="请选择功能"
                        >
                          <a-select-option
                            v-for="b in behaviorAllList"
                            :key="b.value"
                            :value="b.value"
                          >
                            {{ b.label }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 18%">
                        <a-select
                          v-model:value="condition.operator"
                          placeholder="请选择操作符"
                        >
                          <a-select-option
                            v-for="ol in operatorList"
                            :key="ol.value"
                            :value="ol.value"
                          >
                            {{ ol.label }}
                          </a-select-option>
                        </a-select>
                      </td>
                      <td style="width: 35%">
                        <a-input
                          v-model:value="condition.value"
                          placeholder="请输入..."
                        />
                      </td>
                    </tr>
                    <!--行为满足2-->
                    <tr v-if="condition.type === 'behavior'">
                      <td></td>
                      <td colspan="3">
                        <a-range-picker
                          v-model:value="condition.secondValueArray"
                          style="width: 100%"
                          show-time
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="changeBehaviorTime(index, conditionIndex)"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </li>

              <!--条件添加按钮 -->
              <li class="condition-item">
                <div class="condition-type-wrapper">
                  <a class="or-btn"></a>
                  <div class="condition-type-list">
                    <a
                      class="condition-type-a"
                      @click="addCondition('property', index)"
                    >
                      属性满足
                    </a>
                    <a
                      class="condition-type-a"
                      @click="addCondition('behavior', index)"
                    >
                      行为满足
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <!--条件组添加按钮 -->
          <li class="condition-group-item">
            <div class="condition-group-type-wrapper">
              <a class="and-btn"></a>
              <div class="condition-type-list">
                <a
                  class="condition-type-a"
                  @click="addConditionGroup('property')"
                >
                  属性满足
                </a>
                <a
                  class="condition-type-a"
                  @click="addConditionGroup('behavior')"
                >
                  行为满足
                </a>
              </div>
            </div>
          </li>
        </ul>
        <!--分群规则 end-->
      </div>
    </a-card>
    <div class="ta-right">
      <a-space>
        <a-button type="primary" @click="handleSave"> 保存 </a-button>
        <a-button @click="$router.go(-1)"> 返回 </a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Form,
  Row,
  Col,
  Space,
  Tag,
  Tree,
  Select,
  DatePicker,
} from 'ant-design-vue';
import clusteringApi from '@/api/clustering.js';
import integralApi from '@/api/integral.js';
import tagApi from '@/api/tag.js';

export default {
  components: {
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ATree: Tree,
    ATag: Tag,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
  },
  data() {
    return {
      code: '',
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t('rules.require'),
            trigger: 'blur',
          },
        ],
      },
      treeLoading: false,
      tagTree: [],
      defaultProps: {
        children: 'children',
        label: 'tag_name',
      },
      treeDefaultChecked: [],
      dynamicTags: [],
      groupRuleList: [],
      operatorListMap: {},
      propertyList: [],
      actionPropertyList: [], //动作属性
      operatorList: [
        { label: '大于', value: '>' },
        { label: '小于', value: '<' },
        { label: '等于', value: '=' },
      ],
      behaviorAllList: [
        { label: '总次数', value: 'count' },
        { label: '总量', value: 'total' },
      ],

      areaOptions: [],
      segment: {
        name: '',
        remark: '',
        label: 'label',
        memberGroupDetailList: [],
        conditionGroupList: [],
        groupRuleList: [],
      },
      is_activateOptions: [
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '待激活',
        },
        {
          value: '2',
          label: '已激活',
        },
      ],
      loading: false,
      areaDeptOptions: [],
      CUSTOMETYPEOptions: [],
      industryLv1Options: [],
      JobTitleOptions: [],
    };
  },
  // async activated() {
  //   const { id } = this;
  //   this.loading = true;
  //   await this.getAreaOptions();
  //   const areaDeptRes = await integralApi.getAreaDeptOptions();
  //   this.areaDeptOptions = Object.values(
  //     areaDeptRes.data.AREA_DEPT
  //   ).map(item => ({ value: item }));
  //   const INDUSTRYRes = await integralApi.getIndustryLv1Options();
  //   this.industryLv1Options = Object.values(
  //     INDUSTRYRes.data.INDUSTRY
  //   ).map(item => ({ value: item }));
  //   const CUSTOMETYPERes = await integralApi.getCUSTOMETYPEOptions();
  //   this.CUSTOMETYPEOptions = Object.values(
  //     CUSTOMETYPERes.data.CUSTOMETYPE
  //   ).map(item => ({ value: item }));
  //   const JobTitleRes = await integralApi.getJobTitleOptions();
  //   this.JobTitleOptions = Object.values(
  //     JobTitleRes.data['Job Title']
  //   ).map(item => ({ value: item }));

  //   if (id) {
  //     // 查询分组信息
  //     const res = await clustering.findMemberGroup(id);
  //     res && Object.assign(this.segment, res.data);

  //     // 查询分组标签
  //     const tagRes = await clustering.getGroupTag(id);
  //     tagRes && (this.dynamicTags = tagRes.data);

  //     // 查询分组规则
  //     const ruleRes = await clustering.getGroupRules(id);
  //     const tempRuleArr = Object.values(ruleRes.data);
  //     // 格式化数据
  //     tempRuleArr.map(val => {
  //       // 格式化日期数据  &&  格式化国家数据 英文 =》 中文
  //       val.map(rule => {
  //         rule.secondValue &&
  //           (rule.secondValueArray = rule.secondValue.split('@@'));
  //         if (rule.col !== 'country') return;
  //         Object.assign(rule, {
  //           value: this.areaChEntransformation(rule.value, 'Ch'),
  //         });
  //       });
  //       // 格式化数据格式
  //       this.segment.conditionGroupList.push({
  //         id: null,
  //         value: '',
  //         type: '',
  //         ruleList: val,
  //       });
  //     });
  //   }
  //   this.loading = false;
  //   this.getPropertyRule();
  //   this.getPropertySelector();
  //   this.getActionSelector();
  //   // this.getTagDetails();
  //   this.getTagTreeList();
  // },
  created() {
    this.initTagDetails();
    this.initTagTreeList();
    this.initPropertySelector();
    this.initPropertyRule();
    this.initActionSelector();
  },
  methods: {
    initTagDetails() {
      const id = this.$route.params.id;
      if (id) {
        if (id === 'add') {
          this.code = 'group:add';
        } else {
          this.code = 'group:update';
          clusteringApi.get(this.code, id).then(data => {
            this.form = data;
          });
          clusteringApi.getGroupTagsById(this.code, id).then(data => {
            this.dynamicTags = data;
          });
          clusteringApi.getGroupRulesById(this.code, id).then(data => {
            this.groupRuleList = Object.values(data);
          });
        }
      }
    },
    initTagTreeList() {
      this.treeLoading = true;
      clusteringApi
        .getTagTreeList(this.code)
        .then(data => {
          const tempTreeData = [];
          const keyAndVal = Object.entries(data);
          const allItems = [];
          keyAndVal.forEach(val => {
            tempTreeData.push({
              tag_name: val[0],
              children: val[1],
              tag_code: `${val[0]}-${new Date().getTime()}`,
            });
            allItems.push(...val[1]);
          });
          this.tagTree = tempTreeData;

          // 处理默认选中
          let tempTreeDefaultChecked = [];
          this.dynamicTags.map(item => {
            allItems.some(treeItem => {
              if (item.tagCode === treeItem.tag_code) {
                tempTreeDefaultChecked.push(treeItem.tag_code);
                item.tagName = treeItem.tag_name;
                return true;
              }
            });
          });

          this.treeDefaultChecked = tempTreeDefaultChecked;
        })
        .finally(() => {
          this.treeLoading = false;
        });
    },
    initPropertySelector() {
      tagApi.getPropertySelector(this.code).then(({ contact }) => {
        this.propertyList = contact;
      });
    },
    initPropertyRule() {
      tagApi.getPropertyRule(this.code).then(data => {
        this.operatorListMap = data;
      });
    },
    initActionSelector() {
      tagApi.getActionSelector(this.code).then(({ xingwei }) => {
        this.actionPropertyList = xingwei;
      });
    },
    handleClose(tag) {
      // 删除临时展示数据
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$refs.tagTree.setChecked(tag.tagCode, false);

      // 有时候不触发change方法，手动调用
      this.tagTreeCheckChange();
    },
    tagTreeCheckChange(checkedKeys, { checkedNodes }) {
      console.log(checkedKeys);
      console.log(checkedNodes);

      // 标签展示数据
      this.dynamicTags = checkedNodes.map(({ props }) => {
        return { tagName: props.tag_name, tagCode: props.tag_code };
      });
    },
    addConditionGroup(type) {
      this.groupRuleList.push([]);
      this.addCondition(type, this.groupRuleList.length - 1);
    },
    addCondition(type, index) {
      this.groupRuleList[index].push({
        conditionId: index + 1,
        action: null,
        col: null,
        operator: null,
        value: '',
        dataType: '',
        type,
      });
    },
    removeRule(index, conditionIndex) {
      this.groupRuleList[index].splice(conditionIndex, 1);
      if (!this.groupRuleList[index].length) {
        this.groupRuleList.splice(index, 1);
      }
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          clusteringApi
            .add(this.code, {
              ...this.form,
              memberGroupDetailList: this.dynamicTags,
              groupRuleList: this.groupRuleList.flat(),
            })
            .then(() => {
              this.$message.success(this.$t('tips.updateSuccess'));
              this.$router.go(-1);
            });
        }
      });
    },
    async save() {
      const { id, operate, dynamicTags, segment } = this;
      const msgType = operate === 'edit' ? '修改' : '新建';
      this.segment.id = id;

      let isValidate = await this.$refs.ruleForm.validate().catch(() => {});

      // 验证不通过
      if (!isValidate)
        return this.$notify({
          message: '验证不通过',
          title: '失败',
          type: 'error',
        });

      this.loading = true;
      // 处理标签参数
      dynamicTags.map(item => {
        segment.memberGroupDetailList.push({
          groupId: id,
          tagCode: item.tagCode,
        });
      });

      // 格式化规则数组
      segment.conditionGroupList.map(item => {
        segment.groupRuleList.push(
          ...item.ruleList.map(rule => {
            if (rule.col !== 'country') return rule;
            // 把中文地区 转为替换为 英文参数
            return Object.assign({}, rule, {
              value: this.areaChEntransformation(rule.value, 'En'),
            });
          })
        );
      });

      Reflect.deleteProperty(segment, 'conditionGroupList');

      const res = await clusteringApi.add(this.code, segment);
      this.$notify({
        message: res ? msgType + '分组成功' : msgType + '分组失败',
        title: res ? '成功' : '失败',
        type: res ? 'success' : 'error',
      });

      this.loading = false;
      if (!res) return;

      this.$destroy();
      this.$router.replace({ name: 'clustering' });
    },

    areaChEntransformation(area, targetLanguage) {
      if (targetLanguage === 'Ch') {
        const areaObj = this.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = this.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    },

    // 获取tag Tree数据

    // 获取地区选择列表
    async getAreaOptions() {
      const res = await integralApi.getAreaOptions();
      const areas = Object.keys(res.data.AREA);
      this.areaOptions = areas.map(key => ({
        en: key,
        value: res.data.AREA[key],
      }));
    },
    handleChangeProperty(value, condition) {
      const temp = this.propertyList.find(i => i.colCode === value);
      condition.dataType = temp.dataType;
    },
    handleChange(index, conditionIndex) {
      this.propertyList.forEach(item => {
        if (
          item.value ==
          this.segment.conditionGroupList[index].ruleList[conditionIndex].col
        ) {
          this.segment.conditionGroupList[index].ruleList[
            conditionIndex
          ].dataType = item.dataType;
          return;
        }
      });
    },
    changeBehaviorTime(index, conditionIndex) {
      let secondValueArray = this.segment.conditionGroupList[index].ruleList[
        conditionIndex
      ].secondValueArray;
      this.segment.conditionGroupList[index].ruleList[
        conditionIndex
      ].secondValue = secondValueArray
        ? secondValueArray[0] + '@@' + secondValueArray[1]
        : '';
    },

    /**
     * 获取行为可选属性
     **/
    getBehaviorList() {
      tagApi
        .getBehaviorList()
        .then(() => {})
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.iss-space {
  flex-wrap: wrap;
}
.iss-regulations .condition-group-item {
  display: flex;
  padding-bottom: 30px;
}
.iss-regulations .condition-group-type-wrapper {
  position: relative;
}
.iss-regulations .condition-group-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 36px;
  bottom: -30px;
  left: 50%;
  width: 3px;
  transform: translateX(-50%);
  background-color: #9fe5ef;
}

.iss-regulations
  .condition-group-item:last-child
  .condition-group-type-wrapper:before {
  display: none;
}

.iss-regulations .and-btn {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  background-color: #3bc7db;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .and-btn:before {
  content: 'And';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .and-btn:before {
  content: 'A';
}

.iss-regulations .condition-group-item:last-child .and-btn:before {
  content: '+';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .remove-btn {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  text-align: center;
  background-color: rgb(253, 0, 0);
  border-radius: 100%;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.iss-regulations .remove-btn:before {
  content: '✕';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .remove-btn:before {
  content: '✕';
}

.iss-regulations .condition-group-item:last-child .remove-btn:before {
  content: '✕';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .condition-type-list {
  display: none;
  vertical-align: middle;
}

.iss-regulations .condition-type-wrapper:hover .condition-type-list,
.iss-regulations .condition-group-type-wrapper:hover .condition-type-list {
  display: inline-block;
}

.iss-regulations .condition-type-a {
  display: inline-block;
  width: auto;
  height: 24px;
  line-height: 24px;
  padding: 0px 12px;
  border-radius: 24px;
  color: #3bc7db;
  background-color: #f3f9fb;
}

.iss-regulations .condition-type-a:hover {
  color: #fff;
  background-color: #3bc7db;
  text-decoration: none;
}

.iss-regulations .or-btn {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #a1a6aa;
  text-align: center;
  background-color: #edf2f5;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .condition-item:last-child .or-btn {
  color: #99a0a6;
  background-color: #fff;
  border: 1px solid #edf2f5;
}

.iss-regulations .or-btn:before {
  content: 'Or';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-item:first-child .or-btn:before {
  content: 'O';
}

.iss-regulations .condition-item:last-child .or-btn:before {
  content: '+';
  font-size: 26px;
  top: -2px;
}

.iss-regulations .condition-list {
  padding-left: 30px;
  width: 100%;
}

.iss-regulations .condition-item {
  display: flex;
}

.iss-regulations .condition-wrapper {
  width: 100%;
  white-space: nowrap;
}

.iss-regulations .condition-type-wrapper {
  position: relative;
  padding-top: 15px;
}

.iss-regulations .condition-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 10px;
  bottom: -30px;
  left: 50%;
  width: 1px;
  transform: translateX(-50%);
  background-color: #ebeff1;
}

.iss-regulations .condition-item:last-child .condition-type-wrapper:before {
  display: none;
}

.iss-regulations .condition-table {
  width: 100%;
}

.iss-regulations .condition-table td {
  padding: 30px 5px 0 5px;
}

.iss-regulations .condition-type {
  display: inline-block;
  color: #39c5d6;
  padding-left: 16px;
}

.iss-regulations .condition-close {
  cursor: pointer;
}

.condition-table .ant-select {
  width: 100%;
}
</style>
