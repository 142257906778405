'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/tagType/page',
  getPage(code, params) {
    return http.get('/account/tagType/page', { params, code });
  },
  getActivityIdNameList(code, params) {
    return http.get('/account/campaign/getIdNameList', { params, code });
  },
  getPropertySelector(code) {
    return http.get('/account/tagPageDetail/propertySelector', { code });
  },
  getPropertyRule(code) {
    return http.get('/account/tagPageDetail/ruleDataInfo', { code });
  },
  getActionSelector(code) {
    return http.get('/account/tagPageDetail/actionSelector', { code });
  },
  getTagDetails(code, id) {
    return http.get(`/account/tagPageDetail/${id}`, { code });
  },
  add(code, data) {
    return http.post('/account/tagType', data, { code });
  },
  update(code, data) {
    return http.put('/account/tagType', data, { code });
  },
  updateDetail(code, data) {
    return http.put('/account/tagPageDetail', data, { code });
  },
  delete(code, params) {
    return http.delete('/account/tagType', { params, code });
  },
  execTag(code, id) {
    return http.get(`/account/ruleExecute/${id}`, { code });
  },
  execTags(code) {
    return http.get('/account/ruleExecute', { code });
  },
};
